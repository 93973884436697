import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus  from 'element-plus'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'element-plus/dist/index.css'
import * as echarts from 'echarts'
import axios from 'axios'


var app = createApp(App);
app.use(router);
app.use(ElementPlus);
app.config.globalProperties.$echarts = echarts
app.config.globalProperties.$axios = axios

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
  
  /* eslint-disable */ 
app.directive('repeat', {
  created(el, binding) {
    el.addEventListener('click', e => {
      el.classList.add('is-disabled')
      el.disabled = true
      //console.log('------click-add-disabled')
      setTimeout(() => {
        el.disabled = false
        el.classList.remove('is-disabled')
        //console.log('------click-remove-disabled')
      }, 2000)
    })
  }
})

app.mount('#app')

